import React, { useEffect, useState } from 'react'

import { authActions } from '../../store/auth-store'
import { BsTrash } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SideMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [sideMenu, setSideMenu] = useState([])
  const [text, setText] = useState('')
  const [link, setLink] = useState('')
  const [sort, setSort] = useState('')

  const getSideMenu = () => {
    const token = window.sessionStorage.getItem('token')
    const requestOptions = {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    fetch(
      'https://api.royalhunting.com/rhs/v1/side-menu/get-menu/?key=suckmyballs',
      requestOptions
    )
      .then(res => res.json())
      .then(data => {
        setSideMenu(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onSubmitHandler = event => {
    event.preventDefault()
    const token = window.sessionStorage.getItem('token')

    const newMenu = {
      text,
      link,
      sort,
    }

    const fd = new FormData()
    fd.append('menuData', JSON.stringify(newMenu))
    const requestOptions = {
      method: 'POST',
      body: fd,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    const url = 'https://api.royalhunting.com/rhs/v1/side-menu/create-menu/'
    fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'success') {
          setText('')
          setLink('')
          setSort('')
          getSideMenu()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onChangeHandler = (index, typeValue) => e => {
    const newArray = sideMenu.map((item, i) => {
      if (index === i) {
        return { ...item, [typeValue]: e.target.value }
      } else {
        return item
      }
    })
    setSideMenu(newArray)
  }

  const onUpdateHandler = index => event => {
    const token = window.sessionStorage.getItem('token')

    const menuData = {
      id: sideMenu[index].id,
      text: sideMenu[index].text,
      link: sideMenu[index].link,
      sort: sideMenu[index].sort,
    }

    const fd = new FormData()
    fd.append('menuData', JSON.stringify(menuData))
    const requestOptions = {
      method: 'POST',
      body: fd,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    const url = 'https://api.royalhunting.com/rhs/v1/side-menu/update-menu/'
    fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'success') {
          getSideMenu()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDeleteHandler = index => event => {
    const token = window.sessionStorage.getItem('token')

    if (window.confirm('Vill du ta bort Länken?')) {
      const id = sideMenu[index].id

      const fd = new FormData()
      fd.append('id', id.toString())
      const requestOptions = {
        method: 'POST',
        body: fd,
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      }
      const url = 'https://api.royalhunting.com/rhs/v1/side-menu/delete-menu/'
      fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data.message === 'success') {
            getSideMenu()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      dispatch(authActions.login())
      getSideMenu()
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="container">
        <div className="row mb-1 mt-1">
          <div className="col-2">Titel</div>
          <div className="col-6">Länk</div>
          <div className="col-2">Sorteringsordning</div>
        </div>
        <form onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-2">
              <input
                type="text"
                value={text}
                required
                onChange={e => setText(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                value={link}
                required
                onChange={e => setLink(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                value={sort}
                required
                onChange={e => setSort(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-2">
              <input type="submit" value="Spara" />
            </div>
          </div>
        </form>
        <hr />
        <div className="row mb-1">
          <div className="col-2">Titel</div>
          <div className="col-6">Länk</div>
          <div className="col-2">Sorteringsordning</div>
        </div>
        {sideMenu &&
          sideMenu.length > 0 &&
          sideMenu.map((menu, index) => (
            <div className="row mb-2" key={menu.id}>
              <div className="col-2">
                <input
                  type="text"
                  defaultValue={menu.text}
                  onChange={onChangeHandler(index, 'text')}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  required
                  defaultValue={menu.link}
                  onChange={onChangeHandler(index, 'link')}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  required
                  defaultValue={menu.sort}
                  onChange={onChangeHandler(index, 'sort')}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-1">
                <div onClick={onUpdateHandler(index)}>
                  <FaSave />
                </div>
              </div>
              <div className="col-1">
                <div onClick={onDeleteHandler(index)}>
                  <BsTrash />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default SideMenu
