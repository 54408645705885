import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { authActions } from '../../store/auth-store'
import classes from './Header.module.css'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.auth.isAuthenticated)

  const logoutHandler = () => {
    window.sessionStorage.clear()
    dispatch(authActions.logout())
    navigate('/')
  }

  const homeHandler = () => {
    navigate('/')
  }

  return (
    <header className={classes.header}>
      <h1 onClick={homeHandler}>RHS Admin</h1>
      {isAuth && (
        <nav>
          <ul>
            <li>
              <Link to="/side-menu">Sidomeny</Link>
            </li>
            <li>
              <Link to="/users">Användare</Link>
            </li>
            <li>
              <Link to="/exam">Provet</Link>
            </li>
            <li>
              <button onClick={logoutHandler}>Logga ut</button>
            </li>
          </ul>
        </nav>
      )}
    </header>
  )
}

export default Header
