import { RouterProvider, Route, Routes } from 'react-router-dom'
import Login from './pages/login/login'
import Users from './pages/users/users'
import Exam from './pages/exam/exam'

function App() {
  return (
    <>
      <Login />
    </>
  )
}

export default App
