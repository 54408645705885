import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import App from './App'
import store from './store'
import Users from './pages/users/users'
import RootLayout from './routes/RootLayout'
import Exam from './pages/exam/exam'
import SideMenu from './pages/sideMenu/sideMenu'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '/', element: <App /> },
      { path: '/side-menu', element: <SideMenu /> },
      { path: '/users', element: <Users /> },
      { path: '/exam', element: <Exam /> },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)
