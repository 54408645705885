import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { authActions } from '../../store/auth-store'
import { AiFillEdit } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const imageMimeType = /image\/(png|jpg|jpeg)/i

const Exam = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [checkedOne, setCheckedOne] = useState(false)
  const [exams, setExams] = useState([])
  const [file, setFile] = useState(null)
  const [fileTarget, setFileTarget] = useState(null)
  const [fileDataURL, setFileDataURL] = useState(null)

  const [keyState, setKeyState] = useState('')

  const inputRef = useRef()

  const handleChangeOne = () => {
    if (!checkedOne === true) {
      resetFileInput()
    }
    setCheckedOne(!checkedOne)
  }

  const [examId, setExamId] = useState(0)
  const [question, setQuestion] = useState('')
  const [answer1, setAnswer1] = useState('')

  const [answer2, setAnswer2] = useState('')
  const [answer3, setAnswer3] = useState('')

  const [answer4, setAnswer4] = useState('')
  const [image, setImage] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState()

  const [isEdit, setIsEdit] = useState(false)

  const getExam = () => {
    const token = window.sessionStorage.getItem('token')
    const requestOptions = {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    fetch(
      'https://api.royalhunting.com/rhs/v1/exam/get-exams/?key=suckmyballs',
      requestOptions
    )
      .then(res => res.json())
      .then(data => {
        setExams(data)

        if (examId > 0) {
          scrollToQuestion()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const wait = n => new Promise(resolve => setTimeout(resolve, n))

  const scrollToQuestion = async () => {
    await wait(2000)
    const element = document.getElementById(examId.toString())
    element.scrollIntoView()
    setExamId(0)
  }

  const imageResetHandler = () => {
    console.log('remove image')
    resetFileInput()
    // setImage('')
    // setFile(null)
    // setFileDataURL(null)
    for (let i = 0; i < exams.length; i++) {
      if (exams[i].id === examId) {
        setImage(exams[i].image)
        break
      }
    }
  }

  const handleFileChange = e => {
    setFile(null)
    setFileDataURL(null)
    setImage('')
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid')
      return
    }

    const testFile = e.target.files
    let fileReader = new FileReader()
    fileReader.readAsDataURL(testFile[0])
    fileReader.onload = event => {
      setFileTarget(event.target.result)
    }

    setFile(file)
  }

  const handleEditQuestion = index => e => {
    setFile(null)
    setFileDataURL(null)
    setIsEdit(true)
    const exam = exams[index]

    setExamId(exam.id)
    setQuestion(exam.question)
    setImage(exam.image)
    setCorrectAnswer(exam.correctAnswer)
    for (let i = 0; i < 4; i++) {
      if (i === 0) {
        setAnswer1(exam.answers[i].text)
      }
      if (i === 1) {
        setAnswer2(exam.answers[i].text)
      }
      if (i === 2) {
        if (exam.answers[i] !== undefined) {
          setAnswer3(exam.answers[i].text)
        } else {
          setAnswer3('')
        }
      }
      if (i === 3) {
        if (exam.answers[i] !== undefined) {
          setAnswer4(exam.answers[i].text)
        } else {
          setAnswer4('')
        }
      }
    }
    const boolConversion = exam.standard === 'true'
    setCheckedOne(boolConversion)
  }

  const resetFileInput = () => {
    let randomString = Math.random().toString(36)
    setKeyState(randomString)
    setFile(null)
    setFileTarget(null)
    setFileDataURL(null)
    setImage('')
  }

  const resetForm = () => {
    resetFileInput()
    // setExamId(0)
    setQuestion('')
    setAnswer1('')
    setAnswer2('')
    setAnswer3('')
    setAnswer4('')
    setCorrectAnswer('')
    setIsEdit(false)
    setCheckedOne(false)
  }

  const onSubmitHandler = () => {
    console.log('submit')
    const token = window.sessionStorage.getItem('token')

    if (!isEdit) {
      setExamId(0)
    }

    const postExamQuestion = {
      id: examId,
      question,
      answer1,
      answer2,
      answer3,
      answer4,
      correctAnswer,
      standard: checkedOne.toString(),
    }

    const fd = new FormData()
    if (file !== null) {
      fd.append('image[]', file)
    }

    fd.append('exam', JSON.stringify(postExamQuestion))
    const requestOptions = {
      method: 'POST',
      body: fd,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }

    let url = ''

    if (isEdit) {
      url = 'https://api.royalhunting.com/rhs/v1/exam/update-exam/'
      if (image.includes('http') || file === null) {
      }
    } else {
      url = 'https://api.royalhunting.com/rhs/v1/exam/create-exam/'
    }

    fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'success') {
          console.log('success')
          resetForm()
          resetFileInput()
          setExams([])
          getExam()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const deleteQuestion = id => e => {
    const token = window.sessionStorage.getItem('token')
    if (window.confirm('Vill du radera frågan?')) {
      const postExamQuestion = {
        id,
      }
      const fd = new FormData()

      fd.append('exam', JSON.stringify(postExamQuestion))
      const requestOptions = {
        method: 'POST',
        body: fd,
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      }

      const url = 'https://api.royalhunting.com/rhs/v1/exam/delete-exam/'

      fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data.message === 'success') {
            console.log('success')
            resetForm()
            setExamId(0)
            resetFileInput()
            setExams([])
            getExam()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      dispatch(authActions.login())
      resetFileInput()
      getExam()
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-6">
            <div style={{ height: '800px', overflowY: 'scroll' }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col" colSpan="2">
                      Fråga
                    </th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {exams &&
                    exams.length > 0 &&
                    exams.map((exam, index) => (
                      <tr key={index} id={exam.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <img
                            src={exam.image}
                            alt=""
                            style={{ width: '100px' }}
                          />
                        </td>
                        <td colSpan="2">{exam.question}</td>
                        <td>
                          <span onClick={handleEditQuestion(index)}>
                            <AiFillEdit />
                          </span>
                        </td>
                        <td>
                          <span onClick={deleteQuestion(exam.id)}>
                            <BsTrash />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <div className="col">
              {!checkedOne === true ? (
                <>
                  {!isEdit && (
                    <>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        key={keyState}
                        ref={inputRef}
                      />
                      <div>{file && `${file.name} - ${file.type}`}</div>
                      {fileDataURL && (
                        <img
                          src={fileDataURL}
                          alt="preview"
                          style={{ maxWidth: '100%', maxHeight: '120px' }}
                        />
                      )}
                    </>
                  )}
                  {isEdit && (
                    <>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        key={keyState}
                        ref={inputRef}
                      />
                      {!fileDataURL && (
                        <>
                          <img
                            src={image}
                            alt=""
                            style={{ width: '100%', maxHeight: '250px' }}
                          />
                        </>
                      )}
                      {fileDataURL && (
                        <>
                          <img
                            src={fileDataURL}
                            alt="preview"
                            style={{ maxWidth: '100%', maxHeight: '120px' }}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={imageResetHandler}
                          >
                            Ta bort bild
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <img
                    src="https://api.royalhunting.com/app-img/standard.jpg"
                    alt=""
                    style={{ width: '100%' }}
                  />
                </>
              )}
            </div>

            <div className="col">
              <label>Fråga:</label>
              <textarea
                col="5"
                rows="5"
                value={question || ''}
                required
                onChange={e => setQuestion(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>

            <div className="row">
              <div className="col-6">
                <label>Svarsalternativ 1:</label>
                <textarea
                  col="5"
                  rows="5"
                  value={answer1 || ''}
                  required
                  onChange={e => setAnswer1(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-6">
                <label>Svarsalternativ 2:</label>
                <textarea
                  col="5"
                  rows="5"
                  value={answer2 || ''}
                  required
                  onChange={e => setAnswer2(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <label>Svarsalternativ 3:</label>
                <textarea
                  col="5"
                  rows="5"
                  value={answer3 || ''}
                  onChange={e => setAnswer3(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-6">
                <label>Svarsalternativ 4:</label>
                <textarea
                  col="5"
                  rows="5"
                  value={answer4 || ''}
                  onChange={e => setAnswer4(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            <div className="col">
              <label>Rätt svar:</label>
              <input
                type="text"
                value={correctAnswer || ''}
                required
                onChange={e => setCorrectAnswer(e.target.value)}
              />
            </div>
            <div className="col">
              <label>
                <input
                  label="Standard bild"
                  type="checkbox"
                  value={checkedOne}
                  checked={checkedOne}
                  onChange={handleChangeOne}
                />
                &nbsp; Standard bild
              </label>
            </div>
            <div className="col-3">
              <input
                type="submit"
                value={isEdit ? 'Uppdatera' : 'Spara'}
                onClick={onSubmitHandler}
              />
              <input type="submit" value="Rensa" onClick={resetForm} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Exam
