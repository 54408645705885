import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authActions } from '../../store/auth-store'
import './login.css'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onSubmitHandler = event => {
    event.preventDefault()

    const user = {
      username,
      password,
    }

    const fd = new FormData()
    fd.append('user', JSON.stringify(user))

    const requestOptions = {
      method: 'POST',
      body: fd,
    }

    fetch('https://api.royalhunting.com/rhs/v1/login/', requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.hasOwnProperty('token')) {
          window.sessionStorage.setItem('token', data.token)
          dispatch(authActions.login())
          navigate('/users')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="container">
      <form className="form-signin" role="form" method="post">
        <h4 className="form-signin-heading">Logga in</h4>
        <input
          type="text"
          className="form-control"
          name="username"
          placeholder=""
          required
          autoFocus
          onChange={e => setUsername(e.target.value)}
        />
        <br />
        <input
          type="password"
          className="form-control"
          name="password"
          placeholder=""
          required
          onChange={e => setPassword(e.target.value)}
        />
        <button
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          name="login"
          onClick={onSubmitHandler}
        >
          Login
        </button>
      </form>
    </div>
  )
}

export default Login
