export const GetDate = () => {
  const d = new Date()
  const currentDate = d.getDate()
  const currentMonth = d.getMonth() + 1

  let strMonth = ''
  let strDate = ''

  if (currentMonth < 10) {
    strMonth = '0' + currentMonth.toString()
  } else {
    strMonth = currentMonth.toString()
  }

  if (currentDate < 10) {
    strDate = '0' + currentDate.toString()
  } else {
    strDate = currentDate.toString()
  }

  const shortYear = d.getFullYear().toString()

  return shortYear + strMonth + strDate
}