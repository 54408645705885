import React, { useEffect, useState } from 'react'

import { authActions } from '../../store/auth-store'
import { GetDate } from '../../helpers/date-service'
import { BsTrash } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [startDate, setStartDate] = useState(GetDate)
  const [endDate, setEndDate] = useState('')
  const [license, setLicense] = useState('')

  const getUsers = () => {
    const token = window.sessionStorage.getItem('token')
    const requestOptions = {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    fetch(
      'https://api.royalhunting.com/rhs/v1/users/get-users/?key=suckmyballs',
      requestOptions
    )
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setUsers(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onSubmitHandler = event => {
    event.preventDefault()
    const token = window.sessionStorage.getItem('token')

    const newUser = {
      username,
      password,
      startDate,
      endDate,
      license,
    }

    const fd = new FormData()
    fd.append('userData', JSON.stringify(newUser))
    const requestOptions = {
      method: 'POST',
      body: fd,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    const url = 'https://api.royalhunting.com/rhs/v1/users/create-user/'
    fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'success') {
          setUsername('')
          setPassword('')
          setStartDate('')
          setEndDate('')
          setLicense('')
          getUsers()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onChangeHandler = (index, typeValue) => e => {
    const newArray = users.map((item, i) => {
      if (index === i) {
        return { ...item, [typeValue]: e.target.value }
      } else {
        return item
      }
    })
    setUsers(newArray)
  }

  const onUpdateHandler = index => event => {
    const token = window.sessionStorage.getItem('token')

    const userData = {
      id: users[index].id,
      username: users[index].username,
      password: users[index].password,
      startDate: users[index].startDate,
      endDate: users[index].endDate,
      license: users[index].totalLicense,
    }

    const fd = new FormData()
    fd.append('userData', JSON.stringify(userData))
    const requestOptions = {
      method: 'POST',
      body: fd,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    }
    const url = 'https://api.royalhunting.com/rhs/v1/users/update-user/'
    fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'success') {
          getUsers()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDeleteHandler = index => event => {
    const token = window.sessionStorage.getItem('token')

    if (window.confirm('Vill du ta bort användaren?')) {
      const id = users[index].id

      const fd = new FormData()
      fd.append('id', id.toString())
      const requestOptions = {
        method: 'POST',
        body: fd,
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      }
      const url = 'https://api.royalhunting.com/rhs/v1/users/delete-user/'
      fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data.message === 'success') {
            getUsers()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      dispatch(authActions.login())
      getUsers()
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="container">
        <div className="row mb-1 mt-1">
          <div className="col-2">Användarnamn</div>
          <div className="col-2">Lösenord</div>
          <div className="col-2">Startdatum</div>
          <div className="col-2">Slutdatum</div>
          <div className="col-2">Licenser</div>
        </div>
        <form onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-2">
              <input
                type="text"
                value={username}
                required
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                value={password}
                required
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                value={startDate}
                required
                onChange={e => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                value={endDate}
                required
                onChange={e => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                value={license}
                required
                onChange={e => setLicense(e.target.value)}
                style={{ width: '50%' }}
              />
            </div>
            <div className="col-2">
              <input type="submit" value="Spara" />
            </div>
          </div>
        </form>
        <hr />
        <div className="row mb-1">
          <div className="col-2">Användarnamn</div>
          <div className="col-2">Lösenord</div>
          <div className="col-2">Startdatum</div>
          <div className="col-2">Slutdatum</div>
          <div className="col-2">Licenser</div>
        </div>
        {users &&
          users.length > 0 &&
          users.map((user, index) => (
            <div className="row mb-2" key={user.id}>
              <div className="col-2">
                <input
                  type="text"
                  defaultValue={user.username}
                  onChange={onChangeHandler(index, 'username')}
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  required
                  defaultValue={user.password}
                  onChange={onChangeHandler(index, 'password')}
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  required
                  defaultValue={user.startDate}
                  onChange={onChangeHandler(index, 'startDate')}
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  required
                  defaultValue={user.endDate}
                  onChange={onChangeHandler(index, 'endDate')}
                />
              </div>
              <div className="col-2">
                {/* LICENSE */}
                <input
                  type="text"
                  required
                  defaultValue={user.totalLicense}
                  onChange={onChangeHandler(index, 'totalLicense')}
                  style={{ width: '50%' }}
                />{' '}
                ({user.freeLicense})
              </div>
              <div className="col-1">
                <div onClick={onUpdateHandler(index)}>
                  <FaSave />
                </div>
              </div>
              <div className="col-1">
                <div onClick={onDeleteHandler(index)}>
                  <BsTrash />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default Users
